import { render, staticRenderFns } from "./roomInfo-detail.vue?vue&type=template&id=bf5a1d8c&scoped=true&"
import script from "./roomInfo-detail.vue?vue&type=script&lang=js&"
export * from "./roomInfo-detail.vue?vue&type=script&lang=js&"
import style0 from "./roomInfo-detail.vue?vue&type=style&index=0&id=bf5a1d8c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf5a1d8c",
  null
  
)

export default component.exports